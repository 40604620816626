import { useState, useEffect } from "react";
import { Button, Col, Form, Row, Alert } from "react-bootstrap";
import { Back } from "react-bootstrap-icons";
import Scrollbar from "smooth-scrollbar";
import {
  validINRCurrency,
  validUSDCurrency,
  getFormatedAmount,
} from "../../functions/validations";
import {
  getAllEnquiryCustomers,
  updateEnquiry,
} from "../../Services/enquiry-customer-service";
import { getLinkedNetworkDetails } from "../../Services/network-service";
import { TriggerMail } from "../../Services/triggermail-service";
import {
  getEnquiryById,
  getIfcUpcDetails,
  getNetworkDetails,
  updateIFC_Details,
} from "../../Services/UpcIfcSolution-service";
import {
  getApprovalStatusByID,
  getApprovalStatus,
} from "../../Services/user-service";
import {
  getWorkFlowRoleByLevel,
  getenergySavedPerDaySummation,
  getenergyConsumptionSummation,
} from "../../Services/workflow-service";
import Export from "../../icons/Export.svg";

import SuccessAlertModal from "../Modals/successAlertModal";
import ClosureRemarks from "./ClosureRemarks";
import CustomTableRoi from "./Tables/CustomTableRoi";
import TableViewA from "./Tables/TableA";
import { decimalFloat, multipleOfFifty } from "../../utils/twoDecimal";
import axios from "axios";
// import axios from "../../utils/axios-trans";
import {downloadEnquirydoc,downloadEnquirydocApproval} from "../../Services/enquiry-transaction-service"
import TableBNew from "./Tables/TableBNew";
import ExecutiveSummary from "./Tables/ExecutiveSummary";
import { getExecSummary } from "../../Services/executive-summary-service";
import { useHistory } from "react-router";
import { removeComa } from "../../functions/functions";
import Loader from "../utility/Loader";
import WarningModal from "../Modals/warningModal";

const EnergySavingROI = (props) => {
  const [loading, setLoading] = useState(false);
  const [showwarning, setshowwarning] = useState(false);
  console.log("energysavingroi", props);
  const API_URL = process.env.REACT_APP_READ_PRESIGNED_ENDPOINT;
  //const APITRANS_URL = "http://localhost:5022/aem/api/enquiry/generatedoc/";
  const APITRANS_URL = process.env.REACT_APP_TRANSACTIONSAPI_URL;

  let enquiryid = props.enquiryid;
  const [table, setTable] = useState([]);
  const [NetworkDetails, setNetworkDetails] = useState([]);


  const [areNetName, setareNetName] = useState([]);
  const [b1Index, setb1Index] = useState([]);
  const [b2Index, setb2Index] = useState([]);
  const [cIndex, setcIndex] = useState([]);
  const [ExecData, setExecData] = useState({});
  const [dIndex, setdIndex] = useState([]);
  // const [ApplName, setApplName] = useState({});
  const [Currency, setCurrency] = useState("");
  const [ElectricityUnitRate, setElectricityUnitRate] = useState(0);
  const [workingDays, setWorkingDays] = useState(0);
  const [annualCostSaving, setannualCostSaving] = useState(0);
  const [FileUrl1, setFileUrl1] = useState("");
  const [IfcCode, setIfcCode] = useState("");
  const [Roi_Years, setRoi_Years] = useState("");
  const [Roi_Months, setRoi_Months] = useState("");
  const [SumInvest, setSumInvest] = useState();
  const [remark, setRemark] = useState("");
  const [showSuccess, setshowSuccess] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [show, setShow] = useState(false);
  const [approval, setApproval] = useState("");
  const [approval_ync, setApproval_ync] = useState();
  const history = useHistory();
  const [energy, setEnergy] = useState(0);
  const [proposedSaving, setproposedSaving] = useState(0);
  const [EnergyConsumption, setEnergyConsumption] = useState(0);
  const [Error, setError] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");

  const [errorLine, setErrorLine] = useState({
    ed_proposed_energysaving: null,
  });
  const [Save, setSave] = useState(0);
  const [Msg, setMsg] = useState("");
  const [id_saved_compresed_air_flow, setid_saved_compresed_air_flow] =
    useState();
  const [id_eng_saved_kwh, setid_eng_saved_kwh] = useState("");
  const [id_product_cost, setid_product_cost] = useState(0);
  const [id_eng_saved_per_day, setid_eng_saved_per_day] = useState("");
  const [id_cost_saving_per_year, setid_cost_saving_per_year] = useState("");
  const [isLoader, setLoader] = useState(false);

  console.log("roiprops", props);
  console.log(props.TableViewA);
  let Sum = 0;
  let Sum1 = 0;
  const enquirykey = props.enquiryid
    ? props.enquiryid
    : localStorage.getItem("enqkey");
  console.log("enqid for sending to upc", enquirykey);
  const [enquirydata, setenquirydata] = useState({
    ed_enquiryid: 0,
    ed_enquirynumber: "",
    ed_roi: 0,
    ed_roi_months: 0,
    ed_enquiryamt: 0,
    ed_status: "",
    ed_pendingwith: "",
    ed_enqstatus: "",
    ed_Save: "",
    ed_estimated_energysaving: "",
    ed_proposed_energysaving: "",
    ed_dailyenergyconsumption: "",
    ed_dailyenergysavingproposed: "",
    ed_dailycostsaving: "",
    ed_annualcostsaving: "",
    ed_totalcostofsystem: "",
    ed_annualco2reduction: ""
  });
  // let currency = "INR";
  console.log("pending", props.pendingWith);

  const getApprovalWf = async () => {
    let result2 = await getApprovalStatus(props.ed_insertedby);
    console.log("getApprovalStatusByID", result2);
    setApproval(result2[0].um_approval);
    console.log("approval", approval);
    // setApproval(props.ed_insertedby)
    // console.log("approval1",approval);
  };

  // const getSummary = async (ed_id) => {
  //   let result2 = await getExecSummary(ed_id);
  //   console.log("sumarry data", result2);
  //   setExecData(result2.data);
  //   // setApproval(result2[0].um_approval);
  //   // console.log("approval", approval);
  //   // setApproval(props.ed_insertedby)
  //   // console.log("approval1",approval);
  // };

  // const getSummary = async (ed_id) => {
  //  getExecSummary(ed_id).then((response)=>{
  //   if (response.data) {
  //     setExecData(response.data);
  //   }
  //  });
  //   // setApproval(result2[0].um_approval);
  //   // console.log("approval", approval);
  //   // setApproval(props.ed_insertedby)
  //   // console.log("approval1",approval);
  // };

  const fetchFilesbyId = async () => {
    let sum1 = 0;
    let sum2 = 0;
    let sum3 = 0;
    let sum4 = 0;
    let sum5 = 0;
    let energyConsumed = 0;
    let IFCUPCDetails = await getIfcUpcDetails(enquirykey, true);

    let result = IFCUPCDetails.IFCdetails;
    if (result) {
      console.log("totalsum", IFCUPCDetails);
      console.log("connections", IFCUPCDetails.ConnectedApplications)
      setNetworkDetails(IFCUPCDetails.ConnectedApplications)
      setTable(result);
      for (let i = 0; i < result.length; i++) {
        sum1 = sum1 + parseFloat(result[i].id_saved_compresed_air_flow);
        console.log("Sum1 ", sum1);
        console.log("PF ", parseFloat(result[i].id_saved_compresed_air_flow));
        sum2 = sum2 + parseFloat(result[i].id_eng_saved_kwh);
        sum3 = sum3 + parseFloat(result[i].id_eng_saved_per_day);
        sum4 = sum4 + parseFloat(result[i].id_cost_saving_per_year);
        sum5 = sum5 + parseFloat(result[i].id_price);
      }
    }

    console.log("id_eng_saved_per_day", id_eng_saved_per_day);
    let consumedRes = await getenergyConsumptionSummation(enquirykey);
    if (consumedRes.status === 200) {
      energyConsumed = consumedRes.data[0].ch_energy_consumption_per_day;
      setEnergyConsumption(energyConsumed);
    }
    setEnergy(Math.floor((sum3 / energyConsumed) * 100));
    // console.log("setEnergy",sum3,energyConsumed,energy);
    // if(enquirydata.ed_proposed_energysaving==null && enquirydata.ed_status=='Created'){
    //   enquirydata.ed_proposed_energysaving=energy;
    //   setenquirydata({
    //     ed_proposed_energysaving: energy,
    //   });
    // }
    //setproposedSaving(Math.floor((sum3 / energyConsumed) * 100));
    setid_saved_compresed_air_flow(sum1.toFixed(4));
    setid_eng_saved_kwh(sum2.toFixed(4));

    setid_eng_saved_per_day(sum3.toFixed(4));

    setid_cost_saving_per_year(sum4.toFixed(2));
    setid_product_cost(sum5.toFixed(2));


  };

  const getEnquiryDetails = async () => {
    console.log("getEnquiryDetails", enquirykey);
    const result1 = await getEnquiryById(enquirykey);
    console.log("incurrecyin fresh", result1[0], result1[0].ed_currencyvalue);
    setCurrency(result1[0].ed_currencyvalue);
    setenquirydata(result1[0]);
    // setElectricityUnitRate(result1[0].ed_electricityunitrate)
    // setWorkingDays(result1[0].ed_electricityunitrate)
  };

  const removeComaIV = (data) => {
    console.log(" ", data);
    if (data !== null) {
      var str = data.toString().split(".");
      // setInvestment_C_Value(0);
      console.log(str[0], str[1]);
      if (str[0].indexOf(",") >= 0) {
        let value = str[0].replace(/\,/g, ""); // 1125, but a string, so convert it to number
        value = parseInt(value, 10);
        console.log(value + "." + str[1]);
        if (str[1]) {
          return value + "." + str[1];
        }
        else {
          return value;
        }
      } else {
        return data;
      }
    }
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    let tmp = [...table];
    console.log("product", value);

    tmp[index][name] = value;
    setTable([...tmp]);
    let sumP = 0;
    for (let i = 0; i < table.length; i++) {
      if (removeComa(table[i].id_price) > 0) {
        sumP = sumP + parseFloat(removeComa(table[i].id_price));
      }
    }
    setid_product_cost(sumP.toFixed(2));
  };
  console.log(table, areNetName);
  for (let i = 0; i < table.length; i++) {
    Sum =
      Sum +
      (parseInt(removeComaIV(table[i].id_price)) || 0) +
      (parseInt(removeComaIV(table[i].id_table_b_invest_value)) || 0) +
      (parseInt(removeComaIV(table[i].id_table_c_invest_value)) || 0) +
      (parseInt(removeComaIV(table[i].id_table_d_invest_value)) || 0);

    Sum1 = Sum1 + (parseInt(table[i].id_cost_saving_per_year) || 0);
  }
  console.log(Sum, Sum1);


  let arr = [];

  function getROIInMmonths() {
    console.log("getROIInMmonths");
    return (Math.round(
      ((Currency === "INR" ? (parseFloat(Sum) / 100000).toFixed(2) : parseFloat(Sum)) / annualCostSaving) * 12
    ))

  }
  function proposedEnergyvalid(value, message) {
    console.log("proposedEnergyvalid", value);
    if (value > enquirydata.ed_estimated_energysaving || value == 0) return "Value should be integer and greater than zero and equal to or less than value in field ‘% Estimated energy saving with Godrej ControlAir Solution IFC";
    return null;
  }

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    console.log("proposed", value);
    // setenquirydata({
    //   ed_estimated_energysaving: energy,
    //   ed_proposed_energysaving: value
    // });
    setproposedSaving(value);
    getROIInMmonths();
    // setproposedSavingNew(value);
    enquirydata.ed_proposed_energysaving = value;
    console.log("enquirydata", enquirydata);
    let ip = Number(energy);
    let sp = Number(value);

    // if (Number(sp) !== 0 ) {
    let ip_sp = (sp > ip);
    console.log("ip sp", ip, sp);
    console.log("ip sp", ip_sp);
    console.log("integer sp", Number.isInteger(sp));

    if (ip_sp || Number(sp) == 0 || (Number.isInteger(sp) !== true)) {
      console.log("ip smaller");
      setAlertMessage(
        "Proposed energy savings Value should be integer and greater than zero and equal to or less than value in field ‘% Estimated energy saving with Godrej ControlAir Solution IFC"
      );

    }
  };

  const validate = () => {
    let flag = true;
    for (let item of Error) {
      console.log("item", item);
      if (item.id_price?.status ||
        item.id_table_b_receiver?.status ||
        item.id_table_b_invest_value?.status ||
        item.id_table_c_invest_value?.status ||
        item.id_table_d_invest_value?.status ||
        item.id_remarks?.status
      ) {
        flag = false;
        break;
      }
    }
    return flag;
  }

  const handleSubmit = async (data) => {
    let ip = Number(energy);
    let sp = Number(proposedSaving);

    // if (Number(sp) !== 0 ) {
    let ip_sp = (sp > ip);
    console.log("ip sp", ip, sp);
    console.log("ip sp", ip_sp);
    console.log("integer sp", Number.isInteger(sp));

    if (ip_sp || Number(sp) == 0 || (Number.isInteger(sp) !== true)) {
      console.log("ip smaller");
      setAlertMessage(
        "Proposed energy savings Value should be integer and greater than zero and equal to or less than value in field ‘% Estimated energy saving with Godrej ControlAir Solution IFC"
      );

    }
    else {
      if (validate()) {
        setLoader(true);
        enquirydata.ed_estimated_energysaving = energy.toString();
        if (proposedSaving != null || proposedSaving != 0) {
          enquirydata.ed_proposed_energysaving = proposedSaving.toString();
        }
        enquirydata.ed_dailyenergyconsumption = Math.floor(enquirydata.ed_status == 'Created' ? multipleOfFifty(
          EnergyConsumption) : enquirydata.ed_dailyenergyconsumption ?
          enquirydata.ed_dailyenergyconsumption : EnergyConsumption).toString();
        enquirydata.ed_dailyenergysavingproposed = Math.floor((enquirydata.ed_status == 'Created' ? multipleOfFifty(
          EnergyConsumption) : enquirydata.ed_dailyenergyconsumption ?
          enquirydata.ed_dailyenergyconsumption : EnergyConsumption) * (enquirydata.ed_proposed_energysaving ? enquirydata.ed_proposed_energysaving : energy) / 100).toString();
        enquirydata.ed_dailycostsaving = (Math.floor((enquirydata.ed_status == 'Created' ? multipleOfFifty(
          EnergyConsumption) : enquirydata.ed_dailyenergyconsumption ?
          enquirydata.ed_dailyenergyconsumption : EnergyConsumption) * (enquirydata.ed_proposed_energysaving ? enquirydata.ed_proposed_energysaving : energy) / 100) * enquirydata.ed_electricityunitrate).toFixed(2).toString();
        enquirydata.ed_annualcostsaving = annualCostSaving ? annualCostSaving.toString() :
          (((Currency == "INR") ? enquirydata.ed_dailycostsaving * enquirydata.ed_workingdays / 100000 : enquirydata.ed_dailycostsaving * enquirydata.ed_workingdays).toFixed(2).toString());
        enquirydata.ed_totalcostofsystem = (enquirydata.ed_currencyvalue === "INR" ? (Sum / 100000).toFixed(2) : Sum).toString();
        enquirydata.ed_annualco2reduction = ((enquirydata.ed_dailyenergysavingproposed * enquirydata.ed_workingdays * 0.79) / 1000).toFixed(2).toString();
        enquirydata.ed_enquiryid = enquirykey;
        enquirydata.ed_Save = 1;
        // enquirydata.ed_roi = Math.round((Sum / Sum1) * 10) / 10;
        enquirydata.ed_roi = parseFloat(annualCostSaving) + 0 > 0 ? ((Currency === "INR" ? (Sum / 100000).toFixed(2) : Sum) / annualCostSaving).toFixed(1) : 0;
        let ansRoiMon = 0;
        if (Math.ceil((Sum / Sum1) * 12) - (Sum / Sum1) * 12 > 0.5) {
          ansRoiMon = Math.floor((Sum / Sum1) * 12);
        } else {
          ansRoiMon = Math.ceil((Sum / Sum1) * 12);
        }
        // {parseFloat(annualCostSaving) + 0 > 0 ? ((Currency === "INR" ? (Sum / 100000).toFixed(2) : Sum) / annualCostSaving).toFixed(1) : 0}
        // {parseFloat(annualCostSaving) + 0 > 0 ?
        //   /* {Math.ceil((Sum / Sum1) * 12) - (Sum / Sum1) * 12 > 0.5
        //   ? Math.floor((Sum / Sum1) * 12)
        //   : Math.ceil((Sum / Sum1) * 12)} */ Math.round(
        //   Math.floor(((Currency === "INR" ? (parseFloat(Sum) / 100000).toFixed(2) : parseFloat(Sum)) / annualCostSaving) * 12)
        // ) : 0
        // }
        enquirydata.ed_roi_months = getROIInMmonths();
        // parseFloat(annualCostSaving) + 0 > 0 ? Math.round(
        //   Math.floor(((Currency === "INR" ? (parseFloat(Sum) / 100000).toFixed(2) : parseFloat(Sum)) / annualCostSaving) * 12)) : 0;
        enquirydata.ed_enquiryamt = Sum;
        enquirydata.ed_status = "Created";
        console.log(enquirydata);

        console.log(data);

        // if (InxError === "") {
        //   console.log("can send");
        // } else {
        //   console.log("cannot send");
        // }

        console.log("hgfdfguyui", table);

        for (let i = 0; i < table.length; i++) {


          if (table[i].id_table_b_invest_value === "" || table[i].id_table_b_invest_value === null) {
            table[i].id_table_b_invest_value = null;
            // var str = table[i].id_table_b_invest_value.toString().split(".");

            // console.log(str[0], str[1]);
            // if (str[0].indexOf(",") >= 0) {
            //   let value = str[0].replace(/\,/g, ""); // 1125, but a string, so convert it to number
            //   value = parseInt(value, 10);
            //   console.log(value + "." + str[1]);
            //   if (str.length === 1) {
            //     table[i].id_table_b_invest_value = value;
            //   } else {
            //     table[i].id_table_b_invest_value = value + "." + str[1];
            //   }
            // }
          }
          else {
            table[i].id_table_b_invest_value = removeComa(table[i].id_table_b_invest_value);
          }

          if (table[i].id_table_c_invest_value === "" || table[i].id_table_c_invest_value === null) {
            table[i].id_table_c_invest_value = null;

            // var strCValue = table[i].id_table_c_invest_value.toString().split(".");

            // console.log(strCValue[0], strCValue[1]);
            // if (strCValue[0].indexOf(",") >= 0) {
            //   let value = strCValue[0].replace(/\,/g, ""); // 1125, but a strCValueing, so convert it to number
            //   value = parseInt(value, 10);
            //   console.log(value + "." + strCValue[1]);
            //   if (strCValue.length === 1) {
            //     table[i].id_table_c_invest_value = value;
            //   } else {
            //     table[i].id_table_c_invest_value = value + "." + strCValue[1];
            //   }
            // }
          }
          else {
            table[i].id_table_c_invest_value = removeComa(table[i].id_table_c_invest_value);
          }

          if (table[i].id_table_d_invest_value === "" || table[i].id_table_d_invest_value === null) {
            table[i].id_table_d_invest_value = null;

            //   var strDValue = table[i].id_table_d_invest_value.toString().split(".");
            //   console.log(strDValue[0], strDValue[1]);
            //   if (strDValue[0].indexOf(",") >= 0) {
            //     let value = strDValue[0].replace(/\,/g, ""); // 1125, but a strDValueing, so convert it to number
            //     value = parseInt(value, 10);
            //     console.log(value + "." + strDValue[1]);
            //     if (strDValue.length === 1) {
            //       table[i].id_table_d_invest_value = value;
            //     } else {
            //       table[i].id_table_d_invest_value = value + "." + strDValue[1];
            //     }
            //   }
          }
          else {
            table[i].id_table_d_invest_value = removeComa(table[i].id_table_d_invest_value);
          }
        }
        let result = updateIFC_Details(table, "UpdateWithROI");
        result
          .then((resp) => {
            console.log(resp);
            if (
              resp.data.message.includes("fails to match the required pattern") ||
              resp.data.message.includes("must be")
            ) {
              let s = resp.data.message;
              s = s.split('_')[1];

              //s = s.substring(s.indexOf("_") + 1, s.indexOf(":"));
              console.log("In Weird Pattern", s);
              setLoader(false);

              // setShowMessage("Special Characters not allowed in IFC");
              // setShow(true);
            } else {
              let resultEDData = updateEnquiry(enquirydata);
              resultEDData.then((resp) => {
                console.log(resp);
                if (
                  resp.message.includes("fails to match the required pattern") ||
                  resp.message.includes("must be")
                ) {
                  let s = resp.message;
                  s = s.split('_')[1];

                  //s = s.substring(s.indexOf("_") + 1, s.indexOf(":"));
                  console.log("In Weird Pattern", s);
                  setLoader(false);
                  // setShowMessage("Special Characters not allowed in ");

                  // setShow(true);
                } else {
                  setMsg(resp.message);
                  setshowSuccess(true);
                  setSave(0);
                  setLoader(false);
                }
              });
            }
          })
          .catch((error) => {

            console.log("Unable to process request");
          })
          .finally(() => {
            setLoader(false);
          });
        //alert("can submit data in Back");
      }
    }
  };

  //submit solution method
  const handleSubmitSolution = async (data) => {
    let ip = Number(energy);
    let sp = Number(proposedSaving);
    // if (Number(sp) !== 0 ) {
    let ip_sp = (sp > ip);
    console.log("ip sp", ip, sp);
    console.log("ip sp", ip_sp);
    console.log("integer sp", Number.isInteger(sp));

    if (ip_sp || Number(sp) == 0 || (Number.isInteger(sp) !== true)) {
      console.log("ip smaller");
      setAlertMessage(
        "Proposed energy savings Value should be integer and greater than zero and equal to or less than value in field ‘% Estimated energy saving with Godrej ControlAir Solution IFC"
      );

    }
    else {
      if (validate()) {
        setLoader(true);
        let level = 1;
        enquirydata.ed_enquiryid = enquirykey;
        enquirydata.ed_Save = 0;
        enquirydata.ed_estimated_energysaving = energy.toString();
        if (proposedSaving != null || proposedSaving != 0) {
          enquirydata.ed_proposed_energysaving = proposedSaving.toString();
        }
        enquirydata.ed_dailyenergyconsumption = Math.floor(enquirydata.ed_status == 'Created' ? multipleOfFifty(
          EnergyConsumption) : enquirydata.ed_dailyenergyconsumption ?
          enquirydata.ed_dailyenergyconsumption : EnergyConsumption).toString();
        enquirydata.ed_dailyenergysavingproposed = Math.floor((enquirydata.ed_status == 'Created' ? multipleOfFifty(
          EnergyConsumption) : enquirydata.ed_dailyenergyconsumption ?
          enquirydata.ed_dailyenergyconsumption : EnergyConsumption) * (enquirydata.ed_proposed_energysaving ? enquirydata.ed_proposed_energysaving : energy) / 100).toString();
        enquirydata.ed_dailycostsaving = (Math.floor((enquirydata.ed_status == 'Created' ? multipleOfFifty(
          EnergyConsumption) : enquirydata.ed_dailyenergyconsumption ?
          enquirydata.ed_dailyenergyconsumption : EnergyConsumption) * (enquirydata.ed_proposed_energysaving ? enquirydata.ed_proposed_energysaving : energy) / 100) * enquirydata.ed_electricityunitrate).toFixed(2).toString();
        enquirydata.ed_annualcostsaving = annualCostSaving.toString();
        enquirydata.ed_totalcostofsystem = (enquirydata.ed_currencyvalue === "INR" ? (Sum / 100000).toFixed(2) : Sum).toString();
        enquirydata.ed_annualco2reduction = ((enquirydata.ed_dailyenergysavingproposed * enquirydata.ed_workingdays * 0.79) / 1000).toFixed(2).toString();
        let approver = await getWorkFlowRoleByLevel(level, enquirykey);
        console.log("approverdata", approver.data);

        if (approver.data.data.length == 0 && approval) {
          setShowMessage(approver.data.message);
          setshowwarning(true);
          setLoader(false);
          return;
        } else {
          if (approver.data.data.length > 0) {
            enquirydata.ed_pendingwith = approver.data.data[0].wm_rolecode;
            enquirydata.ed_enqstatus =
              "Pending with " + approver.data.data[0].wm_rolename;
          }
          enquirydata.ed_enquiryid = enquirykey;
          // enquirydata.ed_roi = Math.round((Sum / Sum1) * 10) / 10;
          enquirydata.ed_roi = parseFloat(annualCostSaving) + 0 > 0 ? ((Currency === "INR" ? (Sum / 100000).toFixed(2) : Sum) / annualCostSaving).toFixed(1) : 0;
          let ansRoiMon = 0;
          if (Math.ceil((Sum / Sum1) * 12) - (Sum / Sum1) * 12 > 0.5) {
            ansRoiMon = Math.floor((Sum / Sum1) * 12);
          } else {
            ansRoiMon = Math.ceil((Sum / Sum1) * 12);
          }
          enquirydata.ed_roi_months = getROIInMmonths();

          // parseFloat(annualCostSaving) + 0 > 0 ? Math.round(
          //   Math.floor(((Currency === "INR" ? (parseFloat(Sum) / 100000).toFixed(2) : parseFloat(Sum)) / annualCostSaving) * 12)) : 0;
          enquirydata.ed_enquiryamt = Sum;
          enquirydata.ed_status = "In-Process";

          console.log(data);
          let result = await getApprovalStatusByID();
          console.log("getApprovalStatusByID", result);

          for (let i = 0; i < table.length; i++) {
            if (table[i].id_table_b_invest_value === "") {
              table[i].id_table_b_invest_value = null;
            }

            if (table[i].id_table_c_invest_value === "") {
              table[i].id_table_c_invest_value = null;
            }

            if (table[i].id_table_d_invest_value === "") {
              table[i].id_table_d_invest_value = null;
            }
          }
          setApproval(result[0].um_approval);
          if (result[0].um_approval === true) {
            console.log("hgfdfguyui", table);
            let result = updateIFC_Details(table, "UpdateWithROI");
            result
              .then((resp) => {
                if (
                  resp.data.message.includes(
                    "fails to match the required pattern"
                  ) ||
                  resp.data.message.includes("must be")
                ) {
                  let s = resp.data.message;
                  s = s.split('_')[1];

                  //s = s.substring(s.indexOf("_") + 1, s.indexOf(":"));
                  console.log("In Weird Pattern", s);
                  // setShowMessage("Special Characters not allowed in IFC ");
                  // setShow(true);
                } else {
                  console.log("Response", resp);

                  let resultEDData = updateEnquiry(enquirydata);
                  resultEDData.then((resp) => {
                    console.log(resp);
                    if (
                      resp.message.includes(
                        "fails to match the required pattern"
                      ) ||
                      resp.message.includes("must be")
                    ) {
                      let s = resp.message;
                      s = s.split('_')[1];
                      //s = s.substring(s.indexOf("_") + 1, s.indexOf(":"));
                      console.log("In Weird Pattern", s);
                      // setShowMessage("Special Characters not allowed in");
                      // setShow(true);
                    } else {
                      setMsg(resp.message);

                      setshowSuccess(true);
                      setSave(1);
                      setLoading(false);
                      // if (approval == false) {
                      //   DownloadDoc(enquirykey, true);
                      // }
                      //window.location = "/allsolution";
                    }
                  });
                }
              })
              .catch((error) => {
                console.log("Unable to process request");
              })
              .finally(() => {
                setLoader(false);
              });
            //alert("can submit data in Back");
          } else {
            console.log("No cant submit", enquirydata);
            enquirydata.ed_pendingwith = "Initiator";
            enquirydata.ed_enqstatus = "Pending for Proposal Status";
            enquirydata.ed_status = "Closed";

            let result = updateIFC_Details(table, "UpdateWithROI");
            result
              .then((resp) => {
                console.log("Response", resp);
                if (
                  resp.data.message.includes(
                    "fails to match the required pattern"
                  ) ||
                  resp.data.message.includes("must be")
                ) {
                  let s = resp.data.message;
                  s = s.split('_')[1];

                  //s = s.substring(s.indexOf("_") + 1, s.indexOf(":"));
                  console.log("In Weird Pattern", s);
                  // setShowMessage("Special Characters not allowed in IFC" );
                  // setShow(true);
                } else {
                  let resultEDData = updateEnquiry(enquirydata);
                  resultEDData.then((resp) => {
                    console.log("updateEnquirydata", resp);
                    if (
                      resp.message.includes(
                        "fails to match the required pattern"
                      ) ||
                      resp.message.includes("must be")
                    ) {
                      let s = resp.message;
                      s = s.split('_')[1];

                      // s = s.substring(s.indexOf("_") + 1, s.indexOf(":"));
                      console.log("In Weird Pattern", s);
                      // setShowMessage("Special Characters not allowed in IFC" );
                      // setShow(true);
                    } else {                    
                      //let mail = TriggerMail(enquirydata.ed_enquiryid);
                      //console.log(mail);
                      let response = downloadEnquirydoc(enquirykey);
                      console.log("data", response);
                      response.then((resp) => {
                        console.log(resp);
                        if (
                          resp.data === "File Generated successfully"
                        ) {
                          setLoading(false);
                            setMsg(resp.message);
                            setshowSuccess(true);
                            setSave(1);
                            // window.location = "/allsolution";
                        } 
                      })
                      .finally(() => {
                        setLoader(false);
                      });
                    }  

                      // axios
                      //   .post(APITRANS_URL + enquirykey + "/preview/true") //set preview=true for sending emails
                      //   .then(function (response) {
                      //     if (response.data === "File Generated successfully") {
                      //       setLoading(false);
                      //       setMsg(resp.message);
                      //       setshowSuccess(true);
                      //       setSave(1);
                      //       // window.location = "/allsolution";
                      //     }
                      //   })
                      //   .finally(() => {
                      //     setLoader(false);
                      //   });
                    
                    });
                }

                //window.location = "/allsolution";
              })
              .catch((error) => {
                console.log("Unable to process request");
              })
              .finally(() => {
                setLoader(false);
              });

            //alert("User not eligible for approval");
          }
          setLoader(false);
        }
      }
    }
  };

  function handleCloseSuccess() {
    setshowSuccess(false);
    if (Save === 1) {
      history.push({
        pathname: "/allsolution",
      });
    }
  }

  const DownloadDoc = async (EnqId, approval_yn) => {
    setLoader(true);
    console.log(
      "In Donwloading Document",
      APITRANS_URL + EnqId + "/preview/" + approval_yn
    );
    let response = downloadEnquirydocApproval(enquirykey,approval_yn);
    console.log("data", response);
     response.then((resp) => {
            console.log("Response from trans",resp);
                    if (
                          resp.data === "File Generated successfully"
                        ) {
                          if (approval_yn === false) {
                            let url = API_URL;
                
                            axios
                              .get(
                                url + "/" + enquirydata.ed_enquirynumber + "_TQ_Sheet" + ".docx"
                              )
                              .then(function (response) {
                                console.log(response.data.url);
                                if (response.data.url) {
                                  let url = response.data.url;
                                  setFileUrl1(url);
                                }
                              })
                              .catch(() => {
                                setShowMessage("Problem in downloading the TQ sheet. Please try again.");
                                setshowwarning(true);
                                setLoader(false);
                              })
                              .finally(() => {
                                setLoader(false);
                              });
                          }
                        } 
                      })
                      .catch(() => {
                        setShowMessage("Problem in downloading the TQ sheet. Please try again.");
                        setshowwarning(true);
                        setLoader(false);
                      })
                      .finally(() => {
                        setLoader(false);
                      });
    // await axios
    //   .post(APITRANS_URL + EnqId + "/preview/" + approval_yn)
    //   .then(function (response) {
    //     console.log("Response from trans", response);
    //     if (response.data === "File Generated successfully") {
    //       if (approval_yn === false) {
    //         let url = API_URL;

    //         axios
    //           .get(
    //             url + "/" + enquirydata.ed_enquirynumber + "_TQ_Sheet" + ".docx"
    //           )
    //           .then(function (response) {
    //             console.log(response.data.url);
    //             if (response.data.url) {
    //               let url = response.data.url;
    //               setFileUrl1(url);
    //             }
    //           })
    //           .catch(() => {
    //             setShowMessage("Problem in downloading the TQ sheet. Please try again.");
    //             setshowwarning(true);
    //             setLoader(false);
    //           })
    //           .finally(() => {
    //             setLoader(false);
    //           });
    //       }
    //     }
    //   })
    //   .catch(() => {
    //     setShowMessage("Problem in downloading the TQ sheet. Please try again.");
    //     setshowwarning(true);
    //     setLoader(false);
    //   })
    //   .finally(() => {
    //     setLoader(false);
    //   });

    //http://localhost:5012/aem/api/enquiry/generatedoc/350
  };

  // if(approval_ync){
  //   DownloadDoc(enquirykey,approval_ync);
  // }



  useEffect(() => {
    if (props.page5key === 5) {
      getEnquiryDetails();
      fetchFilesbyId();
      getApprovalWf();
      // getEnergyDetails();
      //getSummary(enquirykey);
    }

  }, [props.page5key]);

  useEffect(() => {
    console.log("enquirydata", props.enquirydata);
    if (enquirydata.ed_proposed_energysaving == null && enquirydata.ed_status == 'Created') {
      setproposedSaving(energy);
    }
    else if (energy != enquirydata.ed_estimated_energysaving) {
      setproposedSaving(energy);
    }
    else {
      setproposedSaving(props.enquirydata?.ed_proposed_energysaving);
    }
    getROIInMmonths();
  }, [energy]);

  useEffect(() => {
    if (table?.length > 0) {
      let tempData = table;
      let errorData = [];
      if (tempData.length > 0) {
        errorData = tempData.map(() => {
          let tempVal = {
            id_price: "",
            id_table_b_receiver: "",
            id_table_b_invest_value: "",
            id_table_c_invest_value: "",
            id_table_d_invest_value: "",
            id_remarks: "",
          };
          return tempVal;
        });
      }
      setError(errorData);
    }
  }, [table.length])

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 3000);
    return () => clearTimeout(timer);
  }, [alertMessage]);

  return (
    <>

      <WarningModal
        message={showMessage}
        show={showwarning}
        handleClose={() => {
          setshowwarning(false);
        }}
      />
      <SuccessAlertModal
        //clickFunction={modalOnClick}
        msg={Msg}
        show={showSuccess}
        handleClose={handleCloseSuccess}
      />
      <iframe

        hidden
        style={{
          height: 500,
          width: "100%",
          alignContent: "center",
          overflow: "auto",
        }}
        src={FileUrl1}

      />

      <div>
        {isLoader ? (
          <Loader />
        ) : null}
        <div className="" id="Energy-ROI">
          <label style={{ fontWeight: "600", marginBottom: "10px" }}>
            Energy Saving Summary
          </label>

          <TableViewA
            DataTableA={table}
            Total_id_saved_compresed_air_flow={id_saved_compresed_air_flow}
            Total_id_eng_saved_kwh={id_eng_saved_kwh}
            Total_id_eng_saved_per_day={id_eng_saved_per_day}
            Total_id_cost_saving_per_year={id_cost_saving_per_year}
            Total_id_product_cost={id_product_cost}
            NetworkDetails={NetworkDetails}
            setTableA={setTable}
            arr={arr}
            setareNetName={setareNetName}
            Currency={enquirydata.ed_currencyvalue}
            handleChange={handleChange}
            Error={Error}
            setError={setError}
            Disabled={props.IsDisabled}
            page5key={props.page5key}


          />

          <div className="energyPercent">
            <span className="energyLabel">
              % Estimated Energy Savings with Godrej ControlAiR™ IFC ={" "}
              <strong> {energy} % </strong>
            </span>
          </div>
          <div className="energyPercent"
          // className={(enquirydata.ed_status === "Created" || (enquirydata.ed_status !== "Created" && props.enquirydata?.ed_proposed_energysaving != null))? "energyPercent d-block" : "energyPercent d-none"}
          >
           <span className="energyLabel"
            // </div>className={(enquirydata.ed_status === "Created" || (enquirydata.ed_status !== "Created" && props.enquirydata?.ed_proposed_energysaving != null))? "energyLabel d-block" : "energyLabel d-none"
            >
              % Proposed Savings to be Considered for this Case ={" "}
              <strong> <input
                type="text"
                class="proposedSavings"
                disabled={props.IsDisabled === false ? true : false}
                name="propsaving"
                size={4}
                maxLength={3}
                //placeholder={energy}
                // value={(enquirydata.ed_proposed_energysaving&&enquirydata.ed_status=='Created')?enquirydata.ed_proposed_energysaving:0}
                // value={enquirydata.ed_proposed_energysaving}
                value={proposedSaving}

                onChange={(e) => handleChange2(e)}
                onInput={(e) => {
                  let error = proposedEnergyvalid(
                    e.target.value,
                    ""
                  );
                  console.log("error", error);
                  setErrorLine((prevState) => ({
                    ...prevState,
                    ed_proposed_energysaving: error,
                  }));
                }}
              /> %</strong>
              {errorLine.ed_proposed_energysaving?.status && (
                <span className="req-input">
                  {errorLine.ed_proposed_energysaving?.message}
                </span>
              )}
            </span>
          </div>
          <div>
            <span>
              {" "}
              {alertMessage && (
                <Alert className="alertBox" variant="warning">
                  {/* <Alert.Heading>Please check the input!</Alert.Heading> */}
                  {alertMessage}
                </Alert>
              )}
            </span>
          </div>
          <label
            style={{
              fontWeight: "600",
              marginBottom: "10px",
              marginTop: "30px",
            }}
          >
            Table B
          </label>

          <TableBNew
            DataTableB={table}
            currency={enquirydata.ed_currencyvalue}
            handleChange={handleChange}
            setTableB={setTable}
            NetworkDetails={NetworkDetails}
            b1={b1Index}
            b2={b2Index}
            arr={arr}
            areNetName={areNetName}
            Disabled={props.IsDisabled}
            Error={Error}
            setError={setError}
            page5key={props.page5key}
          />
        </div>
      </div>

      <div>
        <ExecutiveSummary
          setannualCostSaving={setannualCostSaving}
          enquirykey={enquirykey}
          DataTableB={table}
          //currency={Currency}
          enquirydata={enquirydata}
          ElectricityUnitRate={ElectricityUnitRate}
          handleChange={handleChange}
          setTableB={setTable}
          b1={b1Index}
          b2={b2Index}
          arr={arr}
          Sum={enquirydata.ed_currencyvalue === "INR" ? (Sum / 100000).toFixed(2) : Sum}
          areNetName={areNetName}
          Disabled={props.IsDisabled}
          page5key={props.page5key}
          energysaving={energy}
          proposedenergysaving={proposedSaving}
          EnergyConsumption={EnergyConsumption}
        />
      </div>
      {/* <div className="mt-3 greenBottomRoi">
        <div className="roiGreenTabLeft">
          ROI in Years <span>{Math.round((Sum / Sum1) * 10) / 10}</span>
        </div>
        <div className="roiGreenTabRight">
          ROI in Months{" "}
          <span>
            {Math.ceil((Sum / Sum1) * 12) - (Sum / Sum1) * 12 > 0.5
              ? Math.floor((Sum / Sum1) * 12)
              : Math.ceil((Sum / Sum1) * 12)}
            {(Sum / Sum1) * 12}
          </span>
        </div>
      </div> */}

      <div className="energyPercent">
        <span className="energyLabel">
          ROI in Years =
          <strong>
            {" "}
            {parseFloat(annualCostSaving) + 0 > 0 ? ((Currency === "INR" ? (Sum / 100000).toFixed(2) : Sum) / annualCostSaving).toFixed(1) : 0}{" "}
          </strong>
        </span>
        <span className="energyLabel">
          ROI in Months ={" "}
          {/* {Sum}{" "}{annualCostSaving}{ " "  }
          {Math.ceil((Sum / annualCostSaving) * 12) - (Sum / annualCostSaving) * 12 } */}
          <strong>
            {getROIInMmonths()}
            {/* {parseFloat(annualCostSaving) + 0 > 0 ?
           
              Math.round(Math.ceil((Sum / annualCostSaving) * 12) - (Sum / annualCostSaving) * 12 > 0.5) ?

                Math.floor(
                  ((Currency === "INR" ? (parseFloat(Sum) / 100000).toFixed(2) : parseFloat(Sum)) / annualCostSaving) * 12
                )
                :

                Math.ceil(
                  ((Currency === "INR" ? (parseFloat(Sum) / 100000).toFixed(2) : parseFloat(Sum)) / annualCostSaving) * 12
                )
              // )
              : 0
            } */}
          </strong>
        </span>
      </div>

      <Row className="mobile-btn-5" style={{ justifyContent: "center" }}>
        {/* <div
          // className={
          //   props.ed_status !== "In-Process" && props.ed_status !== "Closed"
          //     ? "mt-3 whiteBottomRoi"
          //     : "mt-3 whiteBottomRoiEnd"
          // }
          > */}
        {props.ed_status !== "In-Process" && props.ed_status !== "Closed" && (
          <Col className="col-6 col-md-2">
            <div
              className="mt-3"
            // style={{ display: "flex", justifyContent: "space-between" }}
            >
              {/* <span style={{ fontWeight: "600" }}></span> */}
              {props.ed_status !== "In-Process" &&
                props.ed_status !== "Closed" && (
                  <button
                    disabled={props.IsDisabled === false ? true : false}
                    type="button"
                    class={
                      remark ? "btn btn-outline-primary" : "btn btn-primary"
                    }
                    onClick={() => handleSubmit(table)}
                  >
                    <span className="btn-text2">Save Solution</span>
                  </button>
                )}
            </div>
          </Col>
        )}
        {props.ed_status !== "In-Process" && props.ed_status !== "Closed" && (
          <Col className="col-6 col-md-2">
            <div
              className="mt-3"
            // style={{ display: "flex", justifyContent: "space-between" }}
            >
              {/* <span style={{ fontWeight: "600" }}></span> */}
              {props.ed_status !== "In-Process" &&
                props.ed_status !== "Closed" && (
                  <button
                    disabled={props.IsDisabled === false ? true : false}
                    type="button"
                    className={
                      remark ? "btn btn-outline-primary" : "btn btn-primary"
                    }
                    onClick={() => handleSubmitSolution(table)}
                  >
                    <span className="btn-text2">Submit Solution</span>
                  </button>
                )}
            </div>
          </Col>
        )}
        <Col className="col-6 col-md-2">
          <div className="mt-3">
            {/* <span style={{ fontWeight: "600" }}></span> */}
            {
              <button
                type="button"
                className={
                  remark ? "btn btn-outline-primary" : "btn btn-outline-primary"
                }
                onClick={() => DownloadDoc(enquirykey, false)}
              >
                <span className="btn-text2">Preview Solution</span>
              </button>
            }
          </div>
        </Col>
        {/* </div> */}
        {props.status !== "Created" && (
          <Col className="col-6 col-md-2">
            <div className="mt-3">
              {/* <span style={{ fontWeight: "600" }}>
          {" "}
          {remark ? `Closure Remarks` : ``}
        </span> */}

              <button
                type="button"
                className={
                  remark ? "btn btn-outline-primary" : "btn btn-outline-primary"
                }
                onClick={() => setRemark(!remark)}
              >
                <span className="btn-text2">
                  {remark ? `Hide Closure Remarks` : `Closure Remarks`}
                </span>
              </button>
            </div>
          </Col>
        )}
        {/* </div> */}
      </Row>
      {loading ? (
        <div className="loading">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div>
        {remark && (
          <ClosureRemarks
            enquiryid={enquiryid}
            userId={props.userId}
            userRole={props.userRole}
            pendingWith={props.pendingWith}
            ed_status={props.ed_status}
            ed_proposalstatus={props.ed_proposalstatus}
            approval={approval}
            ed_insertedby={props.ed_insertedby}
            setApproval_ync={setApproval_ync}
          />
        )}
      </div>
    </>
  );

};

export default EnergySavingROI;
