import { Navbar, Dropdown, Modal } from "react-bootstrap";
import React, { useState } from "react";
import DownArrow from "../../icons/DownArrow.svg";
import LogOut from "../../icons/LogOut.svg";
import { getInitials } from "../../functions/functions";
import { setLogout } from "../../redux/redux/global/action";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import Toggler from "../../icons/Toggler.svg";
import AEM_Logo from "../../icons/GCEM_Logo.svg";
import ConfirmAlertModal from "../Modals/confirmAlertModal";
import { useEffect } from "react";
const HeaderComp = ({
  menuName,
  setMenuName,
  userName,
  userEmail,
  setShowSidebar,
  showSidebar,
}) => {
  const location = useLocation();
  console.log(location.pathname);
  if (location.pathname === "/dashboard") {
    setMenuName("Dashboard");
  } else if (location.pathname === "/report") {
    setMenuName("Reports");
  } else if (location.pathname === "/newenquiry") {
    setMenuName("Create New Solution");
  } else if (location.pathname === "/allsolution") {
    setMenuName("All Records");
  } else if (location.pathname === "/allsolutionapproval") {
    setMenuName("All Solutions for Approval");
  } else if (location.pathname === "/user") {
    setMenuName("User Master");
  } else if (location.pathname === "/role") {
    setMenuName("Role & Authorization Master");
  } else if (location.pathname === "/dealer") {
    setMenuName("Dealer Master");
  } else if (location.pathname === "/loc") {
    setMenuName("Location Master");
  } else if (location.pathname === "/cust") {
    setMenuName("Customer Master");
  } else if (location.pathname === "/product") {
    setMenuName("Product Master");
  } else if (location.pathname === "/unit") {
    setMenuName("Unit Conversion Master");
  } else if (location.pathname === "/compressor") {
    setMenuName("Compressor Master");
  } else if (location.pathname === "/currency") {
    setMenuName("Currency Master");
  } else if (location.pathname === "/mail") {
    setMenuName("Mail Reminder");
  }
  console.log("menuName",menuName,location.pathname);
  let initials = getInitials(userName);
  let formName = "Logout";
  const [showForm, setShowForm] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { instance } = useMsal();
  const [show, setShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const CustomToggle = React.forwardRef(({ onClick }, ref) => (
    <img
      src={DownArrow}
      alt="arrow"
      className="user-action"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    />
  ));
  function handleLogout(instance) {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  }
  const handleCloseConf = () => {
    setShow(false);
  };

  function handleLogout1() {
    if (localStorage.getItem("Type") === "Non Employee") {
      localStorage.setItem("Type", "");
      dispatch(setLogout());
      history.push("/");
    } else {
      localStorage.setItem("Type", "");
      dispatch(setLogout());
      history.push("/");
      handleLogout(instance);
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 10000);
    return () => clearTimeout(timer);
  }, [alertMessage]);

  return (
    <>
      <Navbar bsPrefix="header">
        <div
          className="d-md-none d-lg-none"
          style={{ width: "100%", display: "flex" }}
        >
          {!showSidebar && (
            <img
              style={{ zIndex: "999" }}
              src={Toggler}
              alt="Toggler"
              class="position-absolute hamburger"
              onClick={setShowSidebar}
            />
          )}
          {!showSidebar && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img src={AEM_Logo} alt="AEM Logo" className="head-logo" />
            </div>
          )}
        </div>
        <div
          className="d-none d-md-flex d-lg-flex"
          style={{ width: "100%", justifyContent: "space-between" }}
        >
          <div className="menu-name-header">{menuName}</div>
          <div className="user-section">
            <div className="user-info">
              <span className="user-name">{userName}</span>
              <span className="user-email">{userEmail}</span>
            </div>
            <div className="user-logo">
              <span>{initials}</span>
            </div>
            <Dropdown autoClose="outside">
              <Dropdown.Toggle as={CustomToggle} />
              <Dropdown.Menu bsPrefix="headerMenu">
                <Dropdown.Item
                  bsPrefix="headerMenu-item"
                  eventKey="1"
                  onClick={() => {
                    setShow(true);
                    // if (localStorage.getItem("Type") === "Non Employee") {
                    //   localStorage.setItem("Type", "");
                    //   dispatch(setLogout());
                    //   history.push("/");
                    // } else {
                    //   localStorage.setItem("Type", "");
                    //   dispatch(setLogout());
                    //   history.push("/");
                    //   handleLogout(instance);
                    // }
                  }}
                >
                  <img src={LogOut} alt="LogOut" /> Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </Navbar>
      <ConfirmAlertModal
        handleYes={handleLogout1}
        formName={formName}
        show={show}
        handleClose={handleCloseConf}
      />
      <Modal
        size="lg"
        className={
          showForm || show
            ? "opacity-0 userMasterModal"
            : "opacity-100 userMasterModal"
        }
      ></Modal>
      <div className="mobile-header">{menuName}</div>
    </>
  );
};

export default HeaderComp;
