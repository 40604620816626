import React, { useState, useEffect, Suspense } from "react";
import "./App.scss";
import { Container, Spinner } from "react-bootstrap";
import ForgotPage from "./Containers/Login/forgotPage";
import SideBarComp from "./Components/layout/SideBarComp";
import HeaderComp from "./Components/layout/HeaderComp";
import { Route, Switch, useLocation } from "react-router-dom";
import LoginPage from "./Containers/Login/LoginPage";
import SSOAuth from "./Containers/Login/SSOAuth";
import { store } from "./redux/store";
import Error from "./Components/Error/error";
//import Error403Dashboard from "./Components/Error/error403";
import { Chart, registerables } from "chart.js";
import axios from "./utils/axios";
import jwt_decode from "jwt-decode";
import encryptData from "./utils/encryption";
import { loginRequest } from "./authConfig";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import {
  setAccessToken,
  setUserDetails,
  setUserName,
} from "./redux/redux/global/action";
import AllSolution from "./Containers/AllSolution/AllSolution";
import AllSolutionApproval from "./Containers/AllSolutionApproval/AllSolutionApproval";
import Reports from "./Containers/Reports/Reports";
import {
  APPROVAL_HIEARCHY_MASTER,
  COMPRESSOR_MASTER,
  COUNTRY_MASTER,
  CUSTOMER_MASTER,
  DEALER_MASTER,
  LOCATION_MASTER,
  MAIL_REMINDER,
  PRODUCT_MASTER,
  ROLE_AUTHORIZATION_MASTER,
  UNIT_CONVERSION_MASTER,
  USER_MASTER,
  CREATE_NEW_SOLUTION,
  DASHBOARD,
  REPORTS,
  ALL_SOLUTION_RECORDS,
  PENDING_FOR_APPROVAL,
} from "./utils/constant";
import Error403Dashboard from "./Components/Error/ErrorDashboard";
import AutoLogOut from "./../src/Components/utility/AutoLogOut";
import Loader from "./Components/utility/Loader";
Chart.register(...registerables);
const Authorization = React.lazy(() =>
  import("./Components/Authorization/Authorization")
);
const Dashboard = React.lazy(() => import("./Containers/Dashboard/Dashboard"));
const LocationMaster = React.lazy(() =>
  import("./Components/Masters/LocationMaster/LocationMaster")
);
const RoleMaster = React.lazy(() =>
  import("./Components/Masters/RoleAndAuthorizationMaster/RoleMaster")
);
const DealerMaster = React.lazy(() =>
  import("./Components/Masters/DealerMaster/DealerMaster")
);
const CustomerMaster = React.lazy(() =>
  import("./Components/Masters/CustomerMaster/CustomerMaster")
);
const UserMaster = React.lazy(() =>
  import("./Components/Masters/UserMaster/UserMaster")
);
const ProductMaster = React.lazy(() =>
  import("./Components/Masters/ProductMaster/ProductMaster")
);
const CountryMaster = React.lazy(() =>
  import("./Components/Masters/CountryMaster/CountryMaster")
);
const MailReminder = React.lazy(() =>
  import("./Components/Masters/MailReminder/MailReminder")
);
const CompressorMaster = React.lazy(() =>
  import(
    "./Components/Masters/CompressorParameterMaster/CompressorParameterMaster"
  )
);
const UnitConversionMaster = React.lazy(() =>
  import("./Components/Masters/UnitConversionMaster/UnitConversionMaster")
);
const NewSolution = React.lazy(() =>
  import("./Components/NewSolution/NewSolution")
);

function App() {
  const [menuName, setMenuName] = useState("Dashboard");
  const [showSidebar, setShowSidebar] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  let access = store.getState().global?.userDetails?.accessData || [];

  console.log("Hi this in app js", access, isAuthenticated);
  if (isAuthenticated === false && access.length > 0) {
    setIsAuthenticated(true);
  }

  const location = useLocation();
  console.log("Applocation", location);

  // function select(state) {
  //   console.log(state)
  //   return state.some.deep.property
  // }

  // let currentValue
  // function handleAccessData() {
  //   let previousValue = currentValue

  //   currentValue = select(store.getState())
  //   console.log(previousValue, currentValue)

  //   if (previousValue !== currentValue) {
  //     console.log(
  //       'Some deep nested property changed from',
  //       previousValue,
  //       'to',
  //       currentValue
  //     )
  //    //elect(store.getState().global?.userDetails?.accessData)
  //     console.log(select(store.getState().global?.userDetails?.accessData))
  //   }
  // }

  return (
    <>
      {/* { (validDealer==true || (validUser==true && isAuthenticated )) &&   */}
      <div>
        {location.pathname !== "/" && <AutoLogOut></AutoLogOut>}
        <Switch>
          <Route
            exact
            path="/"
            render={() => <LoginPage setIsAuthenticated={setIsAuthenticated} />}
          ></Route>
          <Route
            exact
            path="/SSOAuth"
            render={() => <SSOAuth setIsAuthenticated={setIsAuthenticated} />}
          ></Route>
          <Route
            exact
            path="/forgotPassword/:userData?"
            render={() => <ForgotPage />}
          ></Route>
          {/* <Route exact path='/redirect' component={Redirect} /> */}
          {isAuthenticated === true ? (
            <Route
              path="/(.+)"
              render={() => (
                <>
                  <Container fluid className="app-layout">
                    {/* <div className="d-none d-md-block d-lg-block"> */}
                    <SideBarComp
                      showSidebar={showSidebar}
                      setShowSidebar={setShowSidebar}
                      setMenuName={setMenuName}
                      menuName={menuName}
                      userName={store.getState().global?.userDetails?.um_name}
                      userEmail={store.getState().global?.userDetails?.um_email}
                    />
                    {/* </div> */}
                    <Container bsPrefix="app-container">
                      {showSidebar && (
                        <div
                          onClick={() => setShowSidebar(!showSidebar)}
                          className={
                            showSidebar
                              ? "d-md-none d-lg-none blurrOverlay"
                              : "d-none"
                          }
                        ></div>
                      )}
                      <div
                        className={
                          showSidebar ? "d-none d-md-block d-lg-block" : ""
                        }
                      >
                        <HeaderComp
                          showSidebar={showSidebar}
                          setShowSidebar={() => setShowSidebar(!showSidebar)}
                          menuName={menuName}
                          setMenuName={setMenuName}
                          userName={
                            store.getState().global?.userDetails?.um_name
                          }
                          userEmail={
                            store.getState().global?.userDetails?.um_email
                          }
                        />
                        <div className="main-container">
                          <Switch>
                            <Route exact path="/dashboard">
                              <Suspense fallback={<Loader />}>
                                <Authorization Component={Dashboard} />
                              </Suspense>
                            </Route>
                            <Route exact path="/allsolution">
                              <Suspense fallback={<Loader />}>
                                <Authorization
                                  Component={
                                    access.includes(ALL_SOLUTION_RECORDS)
                                      ? AllSolution
                                      : Error403Dashboard
                                  }
                                />
                              </Suspense>
                            </Route>
                            <Route exact path="/loc">
                              <Suspense fallback={<Loader />}>
                                <Authorization
                                  Component={
                                    access.includes(LOCATION_MASTER)
                                      ? LocationMaster
                                      : Error403Dashboard
                                  }
                                />
                              </Suspense>
                            </Route>
                            <Route exact path="/role">
                              <Suspense fallback={<Loader />}>
                                <Authorization
                                  Component={
                                    access.includes(ROLE_AUTHORIZATION_MASTER)
                                      ? RoleMaster
                                      : Error403Dashboard
                                  }
                                />
                              </Suspense>
                            </Route>
                            <Route exact path="/dealer">
                              <Suspense fallback={<Loader />}>
                                <Authorization
                                  Component={
                                    access.includes(DEALER_MASTER)
                                      ? DealerMaster
                                      : Error403Dashboard
                                  }
                                />
                              </Suspense>
                            </Route>
                            <Route exact path="/cust">
                              <Suspense fallback={<Loader />}>
                                <Authorization
                                  Component={
                                    access.includes(CUSTOMER_MASTER)
                                      ? CustomerMaster
                                      : Error403Dashboard
                                  }
                                />
                              </Suspense>
                            </Route>
                            <Route exact path="/user">
                              <Suspense fallback={<Loader />}>
                                <Authorization
                                  Component={
                                    access.includes(USER_MASTER)
                                      ? UserMaster
                                      : Error403Dashboard
                                  }
                                />
                              </Suspense>
                            </Route>

                            <Route exact path="/allsolutionapproval">
                              <Suspense fallback={<Loader />}>
                                <Authorization
                                  Component={
                                    access.includes(PENDING_FOR_APPROVAL)
                                      ? AllSolutionApproval
                                      : Error403Dashboard
                                  }
                                />
                              </Suspense>
                            </Route>
                            <Route exact path="/product">
                              <Suspense fallback={<Loader />}>
                                <Authorization
                                  Component={
                                    access.includes(PRODUCT_MASTER)
                                      ? ProductMaster
                                      : Error403Dashboard
                                  }
                                />
                              </Suspense>
                            </Route>
                            <Route exact path="/currency">
                              <Suspense fallback={<Loader />}>
                                <Authorization
                                  Component={
                                    access.includes(COUNTRY_MASTER)
                                      ? CountryMaster
                                      : Error403Dashboard
                                  }
                                />
                              </Suspense>
                            </Route>
                            <Route exact path="/mail">
                              <Suspense fallback={<Loader />}>
                                <Authorization
                                  Component={
                                    access.includes(MAIL_REMINDER)
                                      ? MailReminder
                                      : Error403Dashboard
                                  }
                                />
                              </Suspense>
                            </Route>
                            <Route exact path="/compressor">
                              <Suspense fallback={<Loader />}>
                                <Authorization
                                  Component={
                                    access.includes(COMPRESSOR_MASTER)
                                      ? CompressorMaster
                                      : Error403Dashboard
                                  }
                                />
                              </Suspense>
                            </Route>
                            <Route exact path="/unit">
                              <Suspense fallback={<Loader />}>
                                <Authorization
                                  Component={
                                    access.includes(UNIT_CONVERSION_MASTER)
                                      ? UnitConversionMaster
                                      : Error403Dashboard
                                  }
                                />
                              </Suspense>
                            </Route>
                            <Route exact path="/newenquiry">
                              <Suspense fallback={<Loader />}>
                                <Authorization
                                  Component={
                                    access.includes(CREATE_NEW_SOLUTION)
                                      ? NewSolution
                                      : Error403Dashboard
                                  }
                                />
                              </Suspense>
                            </Route>
                            <Route exact path="/report">
                              <Suspense fallback={<Loader />}>
                                <Authorization
                                  Component={
                                    access.includes(REPORTS)
                                      ? Reports
                                      : Error403Dashboard
                                  }
                                />
                              </Suspense>
                            </Route>
                            <Route
                              path="**"
                              render={() => (
                                <Suspense fallback={null}>
                                  <Error errorTitle="404 Page Not Found" />
                                </Suspense>
                              )}
                            />
                          </Switch>
                        </div>
                      </div>
                    </Container>
                  </Container>
                </>
              )}
            ></Route>
          ) : <Error errorTitle="404 Page Not Found" />}
        </Switch>
      </div>
      {/* }
    { 
    
    ((validUser==false && isAuthenticated===true) || validDealer ==false || localStorage.getItem('Type')==="") &&
      <LoginPage setValidDealer={setValidDealer} errorMessage={errorMessage}/>} */}
    </>
  );
}

export default App;
